export enum ChannelLogos {
  SRC_CT = 'https://ctfs.ceskatelevize.cz/static/channels/Ceska_televize_logo.svg',
  SRC_CT_24 = 'https://ctfs.ceskatelevize.cz/static/channels/ct24.svg',
  SRC_CT_24_DARK = 'https://ctfs.ceskatelevize.cz/static/channels/ct24_onDark.svg',
  SRC_CT_ART = 'https://ctfs.ceskatelevize.cz/static/channels/ctart.svg',
  SRC_CT_D = 'https://ctfs.ceskatelevize.cz/static/channels/ctd.svg',
  SRC_CT_DARK = 'https://ctfs.ceskatelevize.cz/static/channels/Ceska_televize_logo_onDark.svg',
  SRC_CT_EDU = 'https://ctfs.ceskatelevize.cz/static/channels/edu.svg',
  SRC_CT_EDU_DARK = 'https://ctfs.ceskatelevize.cz/static/channels/edu_onDark.svg',
  SRC_CT_IVYSILANI = 'https://ctfs.ceskatelevize.cz/static/channels/ivysilani.svg',
  SRC_CT_IVYSILANI_DARK = 'https://ctfs.ceskatelevize.cz/static/channels/ivysilani_onDark.svg',
  SRC_CT_MOBILE = 'https://ctfs.ceskatelevize.cz/static/logos/CeskaTelevize.svg',
  SRC_CT_MOBILE_DARK = 'https://ctfs.ceskatelevize.cz/static/logos/CeskaTelevizeDark.svg',
  SRC_CT_SPORT = 'https://ctfs.ceskatelevize.cz/static/channels/ctsport.svg',
  SRC_CT_SPORT_DARK = 'https://ctfs.ceskatelevize.cz/static/channels/ctsport_onDark.svg',
}
