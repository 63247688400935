import React, { ReactNode } from 'react';
import { JssProvider } from 'react-jss';
import { snakeCase } from 'snake-case';

export const JSS_PREFIX = 'ctg-footer__';

const createGenerateId = () => {
  return (rule: any) => {
    if (rule.type === 'keyframes') {
      return (rule as unknown as CSSKeyframesRule).name;
    }

    return `${JSS_PREFIX}${snakeCase(rule.key, { delimiter: '-' })}`;
  };
};

export interface GlobalFooterJssProviderProps {
  children?: ReactNode;
}
export const GlobalFooterJssProvider = ({ children }: GlobalFooterJssProviderProps) => (
  <JssProvider generateId={createGenerateId()}>{children}</JssProvider>
);
