import React, { MouseEvent } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import {
  Blue_dark_background,
  body16,
  BoxShadowFocus,
  DefaultRadius,
  GLOBAL_THEME_CLASSNAME,
  Grey_10,
  Grey_20,
  SrOnly,
} from '@czechtv/styles';
import { footerLinks, staticUrlChannels } from '../common/variables';
import { mediaMinDesktop1024, mediaMinTablet768 } from '../common/breakpoints';
import { link } from '../common/styles';
import analytics from '../common/analytics';
import { GlobalFooterJssProvider } from './GlobalFooterJssProvider';
import { Social } from './components/Social/Social';
import { Channels } from './components/Channels/Channels';
import { SubFooter } from './components/SubFooter/SubFooter';
import { Contacts } from './components/Contacts/Contacts';

const LOGO_SRC = `${staticUrlChannels}Ceska_televize_logo.svg`;
const LOGO_SRC_DARK = `${staticUrlChannels}Ceska_televize_logo_onDark.svg`;

export interface FooterProps {
  background?: string;
  darkMode?: boolean;
  darkModeBackground?: string;
  maxWidth?: number;
}
export interface FooterLinkProps {
  cssClass?: string;
  href: string;
  testId: string;
  title: string;
}

const useStyles = createUseStyles({
  footer: {
    background: ({ background }: FooterProps) => {
      return background || Grey_10;
    },
    '&.darkMode': {
      background: ({ darkModeBackground }: FooterProps) => {
        return darkModeBackground || Blue_dark_background;
      },
    },
    // kvuli ct24
    '& hr': {
      opacity: 1,
    },
  },
  wrapper: {
    display: 'grid',
    maxWidth: ({ maxWidth }: FooterProps) => {
      return `${maxWidth || 1136}px`;
    },
    margin: '0 auto',
    padding: [32, 16, 24, 16],
    [mediaMinTablet768]: {
      gridTemplateColumns: 'repeat(2, auto)',
      gridTemplateRows: 'repeat(6, auto)',
      gridColumnGap: '0px',
      gridRowGap: '0px',
      padding: [48, 24, 32, 24],
    },
    [mediaMinDesktop1024]: {
      gridTemplateRows: 'repeat(6, auto)',
    },
    '& a:focus': {
      outline: 'none',
      borderRadius: DefaultRadius,
      boxShadow: BoxShadowFocus,
    },
  },
  logo: {
    display: 'block',
    justifySelf: 'start',
    marginBottom: 24,
    '& img': {
      height: 24,

      [mediaMinTablet768]: {
        height: 32,
      },
    },
    [mediaMinTablet768]: {
      gridArea: '1 / 1 / 2 / 2',
      marginBottom: 40,
    },
    [mediaMinDesktop1024]: {
      marginBottom: 56,
    },
  },
  social: {
    [mediaMinTablet768]: {
      gridArea: '1 / 2 / 1 / 3',
      justifyContent: 'flex-end',
      alignSelf: 'flex-start',
    },
  },
  contacts: {
    marginBottom: 22,
    [mediaMinTablet768]: {
      gridArea: '4 / 1 / 4 / 3',
    },
    [mediaMinDesktop1024]: {
      gridArea: '3 / 2 / 3 / 3',
    },
  },
  channels: {
    [mediaMinTablet768]: {
      gridArea: '3 / 1 / 3 / 3',
    },
    [mediaMinDesktop1024]: {
      marginBottom: 11,
      gridArea: '2 / 2 / 2 / 3',
      alignSelf: 'start',
    },
  },
  divider: {
    height: 1,
    width: '100%',
    backgroundColor: Grey_20,
    margin: [0, 0, 16, 0],
    border: 'none',
    [mediaMinTablet768]: {
      gridArea: '5 / 1 / 5 / 3',
      margin: [0, 0, 24, 0],
    },
    [mediaMinDesktop1024]: {
      gridArea: '5 / 1 / 5 / 3',
    },
  },
  dividerMedium: {
    margin: [0, 0, 16, 0],
  },
  dividerLow: {
    margin: [0, 0, 20, 0],
    [mediaMinTablet768]: {
      margin: [0, 0, 12, 0],
    },
  },
  dividerMobile: {
    [mediaMinTablet768]: {
      display: 'none',
    },
  },
  linkList: {
    ...body16,
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: '18px',
    listStyle: 'none',
    margin: [0, 0, 20, 0],
    padding: 0,
    '& a': {
      ...link,
    },
    [mediaMinTablet768]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridColumn: '1 / 3',
      margin: [0, 0, 40, 0],
      '& #tvProgram': {
        gridArea: '2 / 1',
      },
      '& #pocasi': {
        gridArea: '3 / 1',
      },
      '& #hbbtvAplikace': {
        gridArea: '4 / 1',
      },
      '& #mobilniAplikace': {
        gridArea: '5 / 1',
      },
      '& #tvPoplatky': {
        gridArea: '6 / 1',
      },
      '& #eshop': {
        gridArea: '7 / 1',
      },
      '& #deckoProRodice': {
        gridArea: '8 / 1',
      },
      '& #teletext': {
        gridArea: '1 / 2',
      },
      '& #casteDotazy': {
        gridArea: '2 / 2',
      },
      '& #zvemeVasDoKina': {
        gridArea: '3 / 2',
      },
      '& #kontakty': {
        gridArea: '4 / 2',
      },
      '& #vseoCt': {
        gridArea: '5 / 2',
      },
      '& #studioBrno': {
        gridArea: '6 / 2',
      },
      '& #studioOstrava': {
        gridArea: '7 / 2',
      },
      '& #radaCt': {
        gridArea: '8 / 2',
      },
    },
    [mediaMinDesktop1024]: {
      display: 'flex',
      gridArea: '2 / 1 / 5 / 2',
      rowGap: '10px',
      margin: [0, 0, 32, 0],
    },
    '&.darkMode': {
      '& a': {
        color: '#FFFFFF',
      },
    },
  },

  linkListItem: {
    flex: '0 1 45%',
    lineHeight: '22px',
    [mediaMinTablet768]: {
      flex: '0 1 33%',
    },
    [mediaMinDesktop1024]: {
      flex: '0 1 50%',
    },
  },
  subFooter: {
    [mediaMinTablet768]: {
      gridArea: '6 / 1 / 6 / 3',
    },
  },
  srOnly: {
    ...SrOnly,
  },
});

// odesle na analytiku otevrenou polozku z paticky
const onLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
  // currentTarget je vzdy <a> (pridavame k nemu listener),
  // target je podle mista kliknuti <img> nebo <a>
  const { target, currentTarget } = e;
  if (target && currentTarget) {
    let item;
    if (currentTarget instanceof HTMLAnchorElement && currentTarget.text) {
      // pokud je v <a> text, pouzijeme ho (a orezeme whitespace)
      item = currentTarget.text.trim();
    } else if (target instanceof HTMLImageElement && target.alt) {
      // v <a> neni text, pouzijeme alespon alt z <img>
      item = target.alt;
    }
    // pokud mame text/alt odkazu, posilame na analytiku
    if (item) {
      analytics.trigger({
        type: 'FooterClick',
        data: {
          footer: {
            type: 'global',
            item,
          },
          interaction: true,
        },
      });
    }
  }
};

export const Footer = ({ darkMode, background, darkModeBackground, maxWidth }: FooterProps) => {
  const classes = useStyles({ background, darkModeBackground, maxWidth });

  return (
    <GlobalFooterJssProvider>
      <footer
        className={classNames(classes.footer, GLOBAL_THEME_CLASSNAME, {
          darkMode,
          darkModeBackground,
        })}
      >
        <div className={classes.wrapper}>
          <a
            className={classes.logo}
            href="https://www.ceskatelevize.cz/"
            title="Úvodní stránka České televize"
            onClick={onLinkClick}
          >
            <img alt="" aria-hidden="true" src={darkMode ? LOGO_SRC_DARK : LOGO_SRC} />
          </a>
          <Contacts className={classes.contacts} darkMode={darkMode} onLinkClick={onLinkClick} />
          <hr className={classNames(classes.divider, classes.dividerLow, classes.dividerMobile)} />
          <h5 className={classes.srOnly}>Rozcestník kanálů</h5>
          <ul className={classNames(classes.linkList, { darkMode })}>
            {footerLinks.map((link) => {
              return (
                <li className={classes.linkListItem} id={link.id} key={link.href}>
                  <a href={link.href} onClick={onLinkClick}>
                    {link.title}
                  </a>
                </li>
              );
            })}
          </ul>
          <hr
            className={classNames(classes.divider, classes.dividerMedium, classes.dividerMobile)}
          />
          <Social className={classes.social} darkMode={darkMode} onLinkClick={onLinkClick} />
          <hr className={classes.divider} />
          <SubFooter className={classes.subFooter} darkMode={darkMode} onLinkClick={onLinkClick} />
          <Channels className={classes.channels} darkMode={darkMode} onLinkClick={onLinkClick} />
        </div>
      </footer>
    </GlobalFooterJssProvider>
  );
};
