import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import { contentClassnames } from './Content.css';

export const CONTENT_PHONE_PADDING = 16;

export interface ContentProps extends HTMLAttributes<HTMLDivElement> {
  childTestId?: string;
  children: React.ReactNode;
  contentClassName?: string;
  inverted?: boolean;
}

export const Content = ({
  children,
  className,
  inverted = false,
  contentClassName,
  childTestId,
  ...rest
}: ContentProps) => {
  return (
    <div {...rest} className={classNames({ [contentClassnames.inverted]: inverted }, className)}>
      <div
        className={classNames(contentClassnames.content, contentClassName)}
        data-testid={childTestId}
      >
        {children}
      </div>
    </div>
  );
};
